<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="value"
        :rules="rules"
        label="Birthday"
        append-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      ref="picker"
      :value="value"
      :max="new Date().toISOString()"
      @input="$emit('input', $event)"
      min="1950-01-01"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: ['value', 'rules'],
  data: () => ({
    menu: false,
  }),
  watch: {
    menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
  },
}
</script>