<template>
  <v-container>
    <v-form
      v-model="validLogin"
      ref="loginForm"
      align="center"
    >
      <v-alert type="success" v-if="message != ''">
        {{ message }}
      </v-alert>
      <v-text-field
        v-model="email"
        label="Email"
        required
        :rules="emailRules"
      ></v-text-field>
      <v-text-field
        v-model="password"
        label="Password"
        required
        :rules="passwordRules"
        :append-icon="hidepass ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="() => (hidepass = !hidepass)"
        :type="hidepass? 'password' : 'text'"
      ></v-text-field>
    </v-form>
      
    
    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" v-if="devmode" outlined :loading="loading" @click="devLogin" class="mr-2">Dev Login</v-btn>
      <v-btn color="primary" :loading="loading" @click="onSubmit">Login</v-btn>
    </v-card-actions>
    <div>
     <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Forget your password? Click here to reset.
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Forgot Password
        </v-card-title>

        <v-card-text>
          Enter your email address below and you will recieve an email with a temporary password.
          <v-text-field
          v-model="reset"
          label="Please enter your email address."
          />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="forgot"
          >
            Reset
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
    <div class="text-center mt-4">
      <span>Are you a doctor or nurse?</span>
      <a @click="microsoftSignIn">
        Click here
      </a>
      <span>to sign in</span>
    </div>
  </v-container>
</template>

<script>
import {api, auth} from '../../sharedPlugin'
import { PublicClientApplication } from '@azure/msal-browser'
import axios from 'axios'

export default {
  props: ['message'],
  data: () => ({
    validLogin: false,
    validSignUp: false,
    email: null,
    reset: '',
    dialog: false,
    password: null,
    passwordConfirm: null,
    hidepass: true,
    emailRules: [
      v => !!v || "Enter your Email",
    ],
    passwordRules: [
      v => !!v || "Enter your Password"
    ],
    loading: false,
    devmode: process.env.NODE_ENV === 'development',
  }),
  methods: {
    ...auth.mapMethods(['login','setUser']),
    forgot(){
      var ip = window.location.host
      var hostname = window.location.hostname
      axios
        .post(`https://dev-inceptionapi.azurewebsites.net/api/v1/connect/auth/forgottwo`, {
          email: this.reset,
          host: ip,
          hostname: hostname
        }, {
          headers: {
            'x-api-key': '58b54801044df5a0536c25db11c653c743761b2db21e044aedb9f91caa6138a5'
          },
        })
        .then((response) => {
          alert('Please check your email for your new password request.')
          this.dialog = false
        })
        .catch((err) => {
          console.error(err)
          this.dialog = false
        })
    },
    onSubmit(){
      if(this.$refs.loginForm.validate()){
        //this.$gtag.event('login', { method: 'Google' })
        this.loading = true;
        this.login({
          input: {
            email: this.email,
            password: this.password
          }, 
          saveCredentials: true
        }).catch(err => alert(err))
          .then(() => this.$router.push({path: this.$route.query.redirect ?? '/'}))
          .finally(() => this.loading = false)
      }
    },
    devLogin(){
      this.loading = true;
      this.login({
        input: {
          email: process.env.VUE_APP_DEV_EMAIL,
          password: process.env.VUE_APP_DEV_PASSWORD
        },
        saveCredentials: true
      })
        .then(() => this.$router.push({path: this.$route.query.redirect ?? '/'}))
        .finally(() => this.loading = false)
    },
    async microsoftSignIn(){
      this.loading = true;
      try{
        const msal = new PublicClientApplication({
          auth: {
            clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
            authority: 'https://login.microsoftonline.com/74cbe500-380f-46da-8078-fdc006248ccc',
            redirectUri: process.env.VUE_APP_URL
          },
          cache: {
            cacheLocation: 'localStorage'
          }
        });
        const result = await msal.loginPopup({scopes: [
          'https://graph.microsoft.com/openid',
          'https://graph.microsoft.com/profile',
          'https://graph.microsoft.com/email'
        ]});
        const login = await api.Auth.ehrUserLogin(result.accessToken)
        this.setUser({user: {...login, email: result.account.username}, save: true});
        this.$router.push({path: this.$route.query.redirect === '/' ? '/messages': this.$route.query.redirect}).catch(() => null)
      }catch(e){
        console.log(e);
        alert('Error logging into microsoft account');
      }
      this.loading = false;
    }
  },
}
</script>