<template>
  <v-container fill-height>
    <v-row justify="center">
      <div class="d-flex flex-column align-center">

        <v-img :src="logourl" max-width="250px" class="mb-8"></v-img>

        <v-card min-width="400px" rounded>

          <v-tabs
            v-model="tab"
            grow
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab>
              Login
            </v-tab>
            <v-tab>
              Sign-up
            </v-tab>
          </v-tabs>

          <v-card-text>
            <v-tabs-items v-model="tab">

              <v-tab-item>
                <Login :message="message"/>
              </v-tab-item>

              <v-tab-item>
                <SignUp @signed-up="signedUp"/>
              </v-tab-item>

            </v-tabs-items>
          </v-card-text>
        </v-card>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import Login from './Login.vue'
import SignUp from './SignUp.vue'
import HavenLogo from '../../assets/HavenCryo2.png'
import PreludeLogo from '../../assets/Prelude.png'

export default {
  data() {
    return {
      tab: null,
      message: "",
      logourl: PreludeLogo
    }
  },
  beforeMount(){
    var url = window.location.href
    if(url.includes("havencryo")){
      this.logourl = HavenLogo
    }
    if(url.includes("localhost")){
      this.logourl = HavenLogo
      this.drawerColor = "white"
      this.text = 'black--text'
    }
  },
  methods: {
    signedUp() {
      this.message = 'signup successful! your password has been emailed to you'
      this.tab = 0
    }
  },

  components: {Login, SignUp},
}
</script>

<style>
html, body {
  height: 100%;
}
.theme--light.v-application {
  background: #f5f5f5;
}
</style>