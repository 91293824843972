<template>
  <v-container>
    <v-form
      v-model="validSignUp"
      ref="signupForm"
      align="center"
    >
      <v-text-field
        v-model="email"
        label="email"
        required
        :rules="emailRules"
      />
      <BirthdayPicker
        v-model="dob" 
        :rules="dobRules"
      />
      <v-select
        v-model="location"
        :items="locations" 
        label="Location"
        :rules="locationRules"
      />
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click="onSubmit">Sign Up</v-btn>
      </v-card-actions>
    </v-form>
  </v-container>
</template>

<script>

import BirthdayPicker from '../../components/BirthdayPicker'
import {api} from '../../sharedPlugin'

export default {
  components: {BirthdayPicker},
  data: () => ({
    email: "",
    dob: undefined,
    location: "",

    validSignUp: false,
    locations: [
      'Aspire Austin', 'Aspire Dallas', 'Aspire Houston', 
      'Aspire San Antonio', 'Houston Fertility Institute', 'Nevada Fertility Institute', 
      'Tennessee Fertility Institute', 'Pacific Ferility Center',
    ],

    emailRules: [
      v => !!v || "Enter your email",
      v => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) || "Email not valid"
    ],
    dobRules: [
      v => v != undefined || "Enter your date of birth"
    ],
    locationRules: [
      v => v != "" && v != null && v != undefined || "Select your location"
    ],
    loading: false
  }),
  methods: {
    signup: api.Auth.signup,
    onSubmit() {
      if(this.$refs.signupForm.validate()) {
        this.loading = true
        this.signup({
          email: this.email,
          dob: this.dob,
          location: this.location
        })
          .then(() => {
            this.$emit('signed-up')
          })
          .catch(error => alert(error))
          .finally(() => {
            this.loading = false
          })
      }
    }
  },
}
</script>